export const logKeyType = (key: string) => {
  if (key.startsWith('pk_test')) {
    console.debug('Stripe: Using test key');
  } else if (key.startsWith('pk_live')) {
    console.debug('Stripe: Using live key');
  } else if (key.startsWith('sk_test')) {
    console.error('Stripe: Using SECRET test key');
  } else if (key.startsWith('sk_live')) {
    console.error('Stripe: Using SECRET LIVE key');
  } else if (key === '' || key === 'no-key') {
    console.error('Stripe: No key provided');
  } else {
    console.error('Stripe: Unknown key type', key);
  }
};
