/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <svg
      className={`logo-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 228 40"
      width="228"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.00903 29.4458L14.3011 35.9714C14.3657 36.0511 14.4176 36.1404 14.4665 36.2306C14.837 36.9132 15.4501 36.9389 15.7896 36.2805C15.852 36.1595 15.8723 36.0219 15.8723 35.8857L15.8722 29.4458H9.00903Z"
        fill="white"
      />
      <path
        className="path"
        d="M32.167 1.72461H35.8693C37.3809 1.72461 38.6062 2.94996 38.6062 4.46151V11.1064C38.6062 11.8851 38.9379 12.6269 39.5182 13.1461L41.8563 15.2381L39.5182 17.3301C38.9379 17.8493 38.6062 18.5911 38.6062 19.3697V25.6725C38.6062 27.1841 37.3809 28.4094 35.8693 28.4094H32.167"
        stroke="#5867EF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.07902"
      />
      <path
        className="path"
        d="M26.1869 28.6846H7.98698C6.47543 28.6846 5.25007 27.4592 5.25007 25.9477V19.3028C5.25007 18.5241 4.91841 17.7823 4.33812 17.2631L2 15.1711L4.33812 13.0791C4.91841 12.5599 5.25007 11.8181 5.25007 11.0394V4.73666C5.25007 3.22511 6.47543 1.99976 7.98698 1.99976H26.1869"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.07902"
      />
      <path
        className="path"
        d="M32.0177 11.7457C31.3502 11.7457 30.7706 11.5379 30.2787 11.1222C29.822 10.6719 29.5938 10.1523 29.5938 9.56337C29.5938 8.93986 29.822 8.42026 30.2787 8.00458C30.7706 7.55426 31.3502 7.3291 32.0177 7.3291C32.6501 7.3291 33.1947 7.55426 33.6514 8.00458C34.1081 8.42026 34.3364 8.93986 34.3364 9.56337C34.3364 10.1523 34.1081 10.6719 33.6514 11.1222C33.1947 11.5379 32.6501 11.7457 32.0177 11.7457ZM32.1758 22.3986C31.9299 22.9874 31.4908 23.2819 30.8584 23.2819C30.5071 23.2819 30.2261 23.1433 30.0153 22.8662C29.8397 22.6237 29.7694 22.312 29.8046 21.9309L30.2787 16.683C30.3139 16.2673 30.5071 15.9209 30.8584 15.6438C31.2448 15.3667 31.6664 15.2281 32.1231 15.2281C32.7555 15.2281 33.2474 15.4533 33.5987 15.9036C33.9852 16.3193 34.1081 16.8215 33.9676 17.4104L32.1758 22.3986Z"
        fill="#5867EF"
      />
      <path
        className="path"
        d="M160.633 22.2481V9.07059H163.771L164.111 11.5021C164.424 10.9269 164.817 10.4389 165.287 10.038C165.775 9.63708 166.324 9.32333 166.934 9.09673C167.562 8.87013 168.25 8.75684 169 8.75684V12.4957H167.797C167.274 12.4957 166.786 12.5567 166.333 12.6787C165.897 12.8007 165.514 13.0012 165.183 13.2801C164.851 13.5415 164.599 13.9076 164.424 14.3782C164.25 14.8488 164.163 15.4414 164.163 16.1561V22.2481H160.633Z"
        fill="white"
      />
      <path
        className="path"
        d="M152.017 22.5618C150.692 22.5618 149.515 22.2829 148.487 21.7252C147.476 21.1499 146.683 20.3569 146.108 19.3459C145.55 18.3175 145.271 17.1322 145.271 15.7901C145.271 14.413 145.55 13.2016 146.108 12.1558C146.683 11.0925 147.476 10.2646 148.487 9.67194C149.498 9.06187 150.674 8.75684 152.017 8.75684C153.324 8.75684 154.466 9.04444 155.442 9.61965C156.418 10.1949 157.176 10.9705 157.716 11.9466C158.257 12.9227 158.527 14.0296 158.527 15.2671C158.527 15.4414 158.527 15.6419 158.527 15.8685C158.527 16.0777 158.509 16.2955 158.475 16.5221H147.781V14.3782H154.971C154.919 13.5241 154.614 12.853 154.056 12.365C153.516 11.8769 152.836 11.6329 152.017 11.6329C151.424 11.6329 150.875 11.7723 150.369 12.0512C149.864 12.3127 149.463 12.7223 149.167 13.2801C148.888 13.8378 148.748 14.5438 148.748 15.3979V16.1561C148.748 16.8707 148.879 17.4982 149.141 18.0386C149.419 18.5615 149.803 18.9711 150.291 19.2674C150.779 19.5463 151.346 19.6858 151.99 19.6858C152.635 19.6858 153.167 19.5463 153.585 19.2674C154.021 18.9886 154.344 18.6312 154.553 18.1955H158.161C157.917 19.0147 157.507 19.7555 156.932 20.4179C156.357 21.0802 155.651 21.6031 154.814 21.9866C153.978 22.3701 153.045 22.5618 152.017 22.5618Z"
        fill="white"
      />
      <path
        className="path"
        d="M141.43 22.248C140.506 22.248 139.695 22.1085 138.998 21.8296C138.318 21.5333 137.787 21.054 137.403 20.3916C137.02 19.7118 136.828 18.788 136.828 17.6202V12.025H134.58V9.0705H136.828L137.22 5.48853H140.358V9.0705H143.835V12.025H140.358V17.6725C140.358 18.2651 140.489 18.6747 140.75 18.9013C141.011 19.1279 141.456 19.2412 142.083 19.2412H143.809V22.248H141.43Z"
        fill="white"
      />
      <path
        className="path"
        d="M120.142 22.2481V9.07059H123.253L123.515 11.2145C123.916 10.4825 124.482 9.88982 125.214 9.43663C125.964 8.98343 126.853 8.75684 127.881 8.75684C128.979 8.75684 129.903 8.98343 130.653 9.43663C131.402 9.88982 131.969 10.5522 132.352 11.4237C132.753 12.2952 132.954 13.3672 132.954 14.6396V22.2481H129.45V14.9795C129.45 13.9337 129.223 13.1319 128.77 12.5741C128.334 12.0163 127.655 11.7375 126.731 11.7375C126.138 11.7375 125.607 11.8769 125.136 12.1558C124.683 12.4347 124.325 12.8356 124.064 13.3585C123.803 13.864 123.672 14.4828 123.672 15.2148V22.2481H120.142Z"
        fill="white"
      />
      <path
        className="path"
        d="M111.525 22.5618C110.201 22.5618 109.024 22.2829 107.996 21.7252C106.985 21.1499 106.192 20.3569 105.616 19.3459C105.059 18.3175 104.78 17.1322 104.78 15.7901C104.78 14.413 105.059 13.2016 105.616 12.1558C106.192 11.0925 106.985 10.2646 107.996 9.67194C109.007 9.06187 110.183 8.75684 111.525 8.75684C112.833 8.75684 113.974 9.04444 114.951 9.61965C115.927 10.1949 116.685 10.9705 117.225 11.9466C117.766 12.9227 118.036 14.0296 118.036 15.2671C118.036 15.4414 118.036 15.6419 118.036 15.8685C118.036 16.0777 118.018 16.2955 117.983 16.5221H107.29V14.3782H114.48C114.428 13.5241 114.123 12.853 113.565 12.365C113.024 11.8769 112.345 11.6329 111.525 11.6329C110.933 11.6329 110.384 11.7723 109.878 12.0512C109.373 12.3127 108.972 12.7223 108.676 13.2801C108.397 13.8378 108.257 14.5438 108.257 15.3979V16.1561C108.257 16.8707 108.388 17.4982 108.649 18.0386C108.928 18.5615 109.312 18.9711 109.8 19.2674C110.288 19.5463 110.854 19.6858 111.499 19.6858C112.144 19.6858 112.676 19.5463 113.094 19.2674C113.53 18.9886 113.852 18.6312 114.062 18.1955H117.67C117.426 19.0147 117.016 19.7555 116.441 20.4179C115.866 21.0802 115.16 21.6031 114.323 21.9866C113.486 22.3701 112.554 22.5618 111.525 22.5618Z"
        fill="white"
      />
      <path
        className="path"
        d="M96.4805 22.5618C95.1558 22.5618 93.9792 22.2655 92.9508 21.6729C91.9224 21.0802 91.1206 20.2697 90.5454 19.2413C89.9702 18.2129 89.6826 17.0363 89.6826 15.7116C89.6826 14.352 89.9702 13.158 90.5454 12.1296C91.1206 11.0838 91.9224 10.2646 92.9508 9.67194C93.9792 9.06187 95.1558 8.75684 96.4805 8.75684C98.1713 8.75684 99.5919 9.20131 100.742 10.0903C101.893 10.9792 102.625 12.1994 102.939 13.7507H99.1997C99.0428 13.1406 98.7116 12.6613 98.2061 12.3127C97.7181 11.9641 97.1342 11.7897 96.4544 11.7897C95.8443 11.7897 95.2952 11.9466 94.8072 12.2604C94.3366 12.5567 93.9618 13.0012 93.6829 13.5938C93.4215 14.169 93.2907 14.8575 93.2907 15.6593C93.2907 16.2694 93.3692 16.8185 93.5261 17.3065C93.6829 17.7771 93.9008 18.1868 94.1797 18.5354C94.4586 18.8665 94.7898 19.1193 95.1732 19.2936C95.5741 19.4679 96.0012 19.555 96.4544 19.555C96.9076 19.555 97.3172 19.4766 97.6832 19.3197C98.0667 19.1629 98.3892 18.945 98.6506 18.6661C98.9121 18.3698 99.0951 18.0124 99.1997 17.5941H102.939C102.625 19.1106 101.884 20.322 100.716 21.2284C99.5657 22.1173 98.1539 22.5618 96.4805 22.5618Z"
        fill="white"
      />
      <path
        className="path"
        d="M83.7889 22.2479V9.07046H87.3186V22.2479H83.7889ZM85.5668 7.2664C84.9393 7.2664 84.4164 7.08338 83.9981 6.71733C83.5972 6.33386 83.3967 5.85452 83.3967 5.27932C83.3967 4.70411 83.5972 4.23348 83.9981 3.86744C84.4164 3.48397 84.9393 3.29224 85.5668 3.29224C86.2118 3.29224 86.7347 3.48397 87.1356 3.86744C87.5539 4.23348 87.7631 4.70411 87.7631 5.27932C87.7631 5.85452 87.5539 6.33386 87.1356 6.71733C86.7347 7.08338 86.2118 7.2664 85.5668 7.2664Z"
        fill="white"
      />
      <path
        className="path"
        d="M67.3623 28.0001V9.07059H70.4998L70.892 10.9008C71.1709 10.5173 71.5021 10.1687 71.8855 9.85496C72.269 9.52378 72.7222 9.26232 73.2451 9.07059C73.7855 8.86142 74.413 8.75684 75.1276 8.75684C76.3652 8.75684 77.4546 9.06187 78.3958 9.67194C79.3371 10.282 80.0779 11.11 80.6182 12.1558C81.176 13.1842 81.4549 14.3608 81.4549 15.6855C81.4549 17.0102 81.176 18.1955 80.6182 19.2413C80.0604 20.2697 79.3109 21.0802 78.3697 21.6729C77.4284 22.2655 76.3565 22.5618 75.1538 22.5618C74.1776 22.5618 73.3323 22.3875 72.6176 22.0389C71.9204 21.6729 71.3452 21.1761 70.892 20.5486V28.0001H67.3623ZM74.3171 19.4766C75.0143 19.4766 75.6244 19.3197 76.1473 19.006C76.6876 18.6922 77.106 18.2478 77.4023 17.6726C77.6986 17.0973 77.8468 16.435 77.8468 15.6855C77.8468 14.936 77.6986 14.2736 77.4023 13.6984C77.106 13.1058 76.6876 12.6526 76.1473 12.3388C75.6244 12.0076 75.0143 11.842 74.3171 11.842C73.6373 11.842 73.0272 12.0076 72.4869 12.3388C71.964 12.6526 71.5456 13.097 71.2319 13.6722C70.9356 14.2475 70.7874 14.9098 70.7874 15.6593C70.7874 16.4088 70.9356 17.0799 71.2319 17.6726C71.5456 18.2478 71.964 18.6922 72.4869 19.006C73.0272 19.3197 73.6373 19.4766 74.3171 19.4766Z"
        fill="white"
      />
      <path
        className="path"
        d="M58.7456 22.5618C57.4209 22.5618 56.2443 22.2829 55.2159 21.7252C54.205 21.1499 53.4119 20.3569 52.8367 19.3459C52.2789 18.3175 52 17.1322 52 15.7901C52 14.413 52.2789 13.2016 52.8367 12.1558C53.4119 11.0925 54.205 10.2646 55.2159 9.67194C56.2269 9.06187 57.4035 8.75684 58.7456 8.75684C60.0529 8.75684 61.1946 9.04444 62.1707 9.61965C63.1468 10.1949 63.9051 10.9705 64.4454 11.9466C64.9857 12.9227 65.2559 14.0296 65.2559 15.2671C65.2559 15.4414 65.2559 15.6419 65.2559 15.8685C65.2559 16.0777 65.2385 16.2955 65.2036 16.5221H54.51V14.3782H61.7001C61.6478 13.5241 61.3428 12.853 60.785 12.365C60.2446 11.8769 59.5648 11.6329 58.7456 11.6329C58.153 11.6329 57.6039 11.7723 57.0984 12.0512C56.5929 12.3127 56.192 12.7223 55.8957 13.2801C55.6168 13.8378 55.4774 14.5438 55.4774 15.3979V16.1561C55.4774 16.8707 55.6081 17.4982 55.8696 18.0386C56.1485 18.5615 56.5319 18.9711 57.02 19.2674C57.508 19.5463 58.0745 19.6858 58.7195 19.6858C59.3644 19.6858 59.896 19.5463 60.3144 19.2674C60.7501 18.9886 61.0726 18.6312 61.2818 18.1955H64.8899C64.6458 19.0147 64.2362 19.7555 63.661 20.4179C63.0858 21.0802 62.3799 21.6031 61.5432 21.9866C60.7065 22.3701 59.774 22.5618 58.7456 22.5618Z"
        fill="white"
      />
    </svg>
  );
};
