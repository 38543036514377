// src/components/PlanSelection.tsx
import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import { PaymentForm } from './PaymentForm';
import { toTitleCase } from '../utils/textUtils';
import { loadFeatures, getFeatureDisplay } from '../utils/featureUtils';
// import { useAuth } from '../hooks/useAuth';
import '../styles/SubscriptionPlans.css';

// import { useSession } from '../contexts/SessionContext';

interface SubscriptionPlan {
  id: string;
  name: string;
  renewalIntervals: {
    interval: string;
    cost: number;
  }[];
  features: string[];
}

interface PlanIdentifier {
  planId: string;
  name: string;
  interval: string;
}

const intervalDisplayMap: { [key: string]: string } = {
  monthly: 'monthly',
  annual: 'annually',
  quarterly: 'quarterly',
  weekly: 'weekly',
  perpetual: 'one time',
};

export const intervalUnitMap: { [key: string]: string } = {
  monthly: 'per month',
  annual: 'per year',
  quarterly: 'per quarter',
  weekly: 'per week',
  perpetual: ' as a one-time payment',
};

export const SubscriptionPlans: React.FC = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanIdentifier | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const { apiKey } = useSession(); // This probably needs to be an unauthenticated page.

  const apiKey = process.env.REACT_APP_API_KEY;
  // const { apiKey } = useAuth();
  // Fetch plans from API
  useEffect(() => {
    const fetchPlans = async () => {
      if (!apiKey) {
        return;
      }
      await loadFeatures(apiKey);
      try {
        const visibility = searchParams.get('visibility') || 'web';
        console.debug(
          `Fetching subscription plans from ${BASE_URL}/subscription-plan?visibility=${visibility}`
        );
        const response = await fetch(
          `${BASE_URL}/subscription-plan?visibility=${visibility}`,
          {
            headers: {
              'X-API-Key': apiKey ?? '',
              'Content-Type': 'application/json',
            },
          }
        );
        const data = await response.json();
        console.debug(data);

        if (response.ok) {
          setPlans(data);
          setLoading(false);
        } else {
          console.error(
            `Failed to fetch subscription plans. API key: ${apiKey}`
          );
          setError('Failed to fetch subscription plans.');
        }
      } catch (error) {
        setError('An error occurred while fetching subscription plans');
        // } finally {
        //   setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = (planId: string, name: string, interval: string) => {
    const plan = { planId, name, interval };
    setSelectedPlan(plan);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedPlan(null);
  };

  // const handleNext = () => {
  //   if (selectedPlan) {
  //     navigate(
  //       `/payment/?planId=${selectedPlan.planId}&interval=${selectedPlan.interval}`
  //     );
  //   }
  // };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="plans-wrapper">
      <div className="plans">
        <h2 className="plans-heading">Select a Subscription Plan</h2>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <div className="plan-group">
          {plans.map((plan) => (
            <div className="plan" key={plan.id}>
              <h3>{plan.name}</h3>
              <div className="plan-features">
                <ul className="plan-feature">
                  {plan.features.map((feature) => (
                    <li
                      key={feature}
                      dangerouslySetInnerHTML={{
                        __html: getFeatureDisplay(feature),
                      }}
                    />
                  ))}
                </ul>
              </div>
              <div className="intervals">
                {plan.renewalIntervals.map((interval) => (
                  <div key={interval.interval}>
                    <div className="interval-cost">
                      {toTitleCase(interval.interval)}: ${interval.cost}
                    </div>
                    <div className="button-container">
                      <Button
                        className="button"
                        variant="outlined"
                        onClick={() =>
                          handleSelectPlan(
                            plan.id,
                            plan.name,
                            interval.interval
                          )
                        }
                      >
                        Select {toTitleCase(plan.name)} with{' '}
                        {toTitleCase(interval.interval)} payments
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
        >
          {selectedPlan && (
            <>
              <div className="selected-plan-info">
                <h2>Subscribe to Epicenter</h2>
                <p>
                  You're purchasing a subscription to the{' '}
                  {toTitleCase(selectedPlan.name)} plan. You will be billed{' '}
                  {intervalDisplayMap[selectedPlan.interval] ||
                    selectedPlan.interval}
                  .
                </p>
                <p className="plan-cost">
                  $
                  {plans
                    .find((p) => p.id === selectedPlan.planId)
                    ?.renewalIntervals.find(
                      (i) => i.interval === selectedPlan.interval
                    )?.cost ?? 0}
                  {' per user '}
                  {intervalUnitMap[selectedPlan.interval] ||
                    `per ${selectedPlan.interval}`}
                </p>
                {/* <p className="plan-description">
                  {plans.find(p => p.id === selectedPlan.planId)?.description}
                </p> */}
                <h3>Features:</h3>
                {plans
                  .find((p) => p.id === selectedPlan.planId)
                  ?.features.map((feature) => (
                    <div
                      key={feature}
                      dangerouslySetInnerHTML={{
                        __html: getFeatureDisplay(feature),
                      }}
                    />
                  ))}
              </div>
              <PaymentForm
                subscriptionTypeId={selectedPlan.planId}
                renewalInterval={selectedPlan.interval}
                onCancel={handleDialogClose}
                cost={
                  plans
                    .find((p) => p.id === selectedPlan.planId)
                    ?.renewalIntervals.find(
                      (i) => i.interval === selectedPlan.interval
                    )?.cost ?? 0
                }
              />
            </>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
