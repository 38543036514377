// src/components/ManageMappingModal.tsx
import React, { useState } from 'react';
import { BASE_URL } from '../config/config';
import { useAuth } from '../hooks/useAuth';
import '../styles/ManageMappingModal.css';
import {
  StripeProduct,
  StripePrice,
  SubscriptionPlan,
  PaymentMetadata,
} from '../types';

interface ManageMappingModalProps {
  product: StripeProduct;
  monthlyPrice?: StripePrice;
  yearlyPrice?: StripePrice;
  subscriptionPlans: SubscriptionPlan[];
  currentMappings: PaymentMetadata[];
  onClose: () => void;
  onSave: () => void;
}

export const ManageMappingModal: React.FC<ManageMappingModalProps> = ({
  product,
  monthlyPrice,
  yearlyPrice,
  subscriptionPlans,
  currentMappings,
  onClose,
  onSave,
}) => {
  const { apiKey } = useAuth();
  const [monthlyPlanId, setMonthlyPlanId] = useState<string>(
    currentMappings.find((m) => m.external_id === monthlyPrice?.id)
      ?.subscription_plan_id || ''
  );
  const [yearlyPlanId, setYearlyPlanId] = useState<string>(
    currentMappings.find((m) => m.external_id === yearlyPrice?.id)
      ?.subscription_plan_id || ''
  );

  const handleSave = async () => {
    try {
      // Update monthly price mapping if it exists
      if (monthlyPrice) {
        await fetch(
          `${BASE_URL}/subscription-plan/${monthlyPlanId}/payment-mapping`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
            body: JSON.stringify({
              priceId: monthlyPrice.id,
              renewalInterval: 'monthly',
            }),
          }
        );
      }

      // Update yearly price mapping if it exists
      if (yearlyPrice) {
        await fetch(
          `${BASE_URL}/subscription-plan/${yearlyPlanId}/payment-mapping`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
            body: JSON.stringify({
              priceId: yearlyPrice.id,
              renewalInterval: 'yearly',
            }),
          }
        );
      }

      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving mappings:', error);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="mapping-modal">
        <h2>Manage Price Mappings</h2>
        <h3>{product.name}</h3>

        {monthlyPrice && (
          <div className="mapping-group">
            <label>Monthly Price (${monthlyPrice.unit_amount / 100})</label>
            <select
              title="Select monthly plan"
              value={monthlyPlanId}
              onChange={(e) => setMonthlyPlanId(e.target.value)}
            >
              <option value="">Select a plan...</option>
              {subscriptionPlans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {yearlyPrice && (
          <div className="mapping-group">
            <label>Annual Price (${yearlyPrice.unit_amount / 100})</label>
            <select
              title="Select annual plan"
              value={yearlyPlanId}
              onChange={(e) => setYearlyPlanId(e.target.value)}
            >
              <option value="">Select a plan...</option>
              {subscriptionPlans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Save Mappings</button>
        </div>
      </div>
    </div>
  );
};
