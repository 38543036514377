// src/hooks/useAuth.ts
import { useState, useEffect } from 'react';
import { API_KEY } from '../config/config';

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  login: string;
  isAdmin: boolean;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  apiKey: string;
  isLoading: boolean;
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    user: null,
    apiKey: API_KEY,
    isLoading: true,
  });

  useEffect(() => {
    console.log('useAuth effect running');
    const sessionApiKey = sessionStorage.getItem('apiKey');
    const sessionUser = sessionStorage.getItem('user');
    console.log('Session data:', {
      sessionApiKey: !!sessionApiKey,
      sessionUser,
    });

    if (sessionApiKey && sessionUser) {
      try {
        const user = JSON.parse(sessionUser);
        console.log('Setting auth state with user:', user);
        setAuthState({
          isAuthenticated: true,
          user,
          apiKey: sessionApiKey,
          isLoading: false,
        });
      } catch (e) {
        console.error('Error parsing user:', e);
        sessionStorage.removeItem('apiKey');
        sessionStorage.removeItem('user');
        setAuthState({
          isAuthenticated: false,
          user: null,
          apiKey: API_KEY,
          isLoading: false,
        });
      }
    } else {
      console.log('No session data found');
      setAuthState((state) => ({ ...state, isLoading: false }));
    }
  }, []);

  // Destructure the loading state out so it doesn't get spread to callers
  const { isLoading, ...authStateWithoutLoading } = authState;

  return {
    ...authStateWithoutLoading,
    isLoading, // Add it as a separate property
    login: (user: User, apiKey: string) => {
      user.isAdmin = true; // for now...
      sessionStorage.setItem('apiKey', apiKey);
      sessionStorage.setItem('user', JSON.stringify(user));
      setAuthState({
        isAuthenticated: true,
        user,
        apiKey,
        isLoading: false,
      });
    },
    logout: () => {
      sessionStorage.removeItem('apiKey');
      sessionStorage.removeItem('user');
      setAuthState({
        isAuthenticated: false,
        user: null,
        apiKey: API_KEY,
        isLoading: false,
      });
    },
  };
};
