// src/components/routes/ProtectedRoutes.tsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const AdminRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();

  console.debug('AdminRoute render:', {
    isAuthenticated,
    user,
    isLoading,
    location,
  });

  if (isLoading) {
    // This is a temporary loading state to prevent flicker
    console.debug('Auth state is loading. Returning null');
    return null;
  }

  if (!isAuthenticated) {
    // Redirect to login, but save where they were trying to go
    console.debug('Not properly authenticated. Redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Assuming you store isAdmin in the user object
  if (!user?.isAdmin) {
    // Redirect non-admin users to home page
    console.info('Not admin. Redirecting to home page');
    return <Navigate to="/" replace />;
  }

  console.debug('User is authenticated as admin. Rendering admin content');
  return <>{children}</>;
};
