import React, { useState } from 'react';
import { Dialog } from './Dialog';

export const LoginForm: React.FC = () => {
  const [isDialogVisible, setDialogVisible] = useState(false);

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle login logic here
    console.log('Logging in...');
    setDialogVisible(false); // Close the dialog on success
  };

  return (
    <Dialog isVisible={isDialogVisible} onClose={() => setDialogVisible(false)}>
      <form onSubmit={handleLogin}>
        <h2>Login</h2>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" required />
        </div>
        <button type="submit">Submit</button>
      </form>
    </Dialog>
  );
};
