import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL, API_KEY } from '../config/config';
import '../styles/Theme.css';
import '../styles/BaseTheme.css';
import '../styles/AccountValidator.css';

export default function AccountValidator() {
  const navigate = useNavigate();
  const location = useLocation();

  const [firstName, setFirstName] = useState('');
  const [hasPassword, setHasPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  // Extract the token from the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('t');

  // Validate token and fetch user data
  useEffect(() => {
    console.debug('Token:', token);
    if (!token) return;

    const fetchUserData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/validate/check-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify({ token }),
        });
        if (!response.ok) throw new Error('Invalid token');

        const data = await response.json();
        setFirstName(data.firstName);
        setHasPassword(data.hasPassword);
      } catch (err) {
        setError(
          'Invalid or expired validation link. Please check your email.'
        );
      }
    };

    fetchUserData();
  }, [token]);

  // Handle password confirmation validation
  const handleValidation = async () => {
    if (password && password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const body = {
        token,
        password: password || undefined,
        confirmPassword: confirmPassword || undefined,
      };

      const response = await fetch(`${BASE_URL}/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const { redirectUrl } = await response.json();
        setSuccess(true);
        setTimeout(() => (window.location.href = redirectUrl), 2000);
      } else {
        const data = await response.json();
        setError(data.error || 'Validation failed');
      }
    } catch (err) {
      setError('Network error occurred');
    }
  };

  // If no token, show a message to check email
  if (!token) {
    return (
      <div className="account-validator-container">
        <h2 className="account-validator-heading">Check Your Email</h2>
        <p>
          Please check your email for a validation link to complete your signup.
        </p>
      </div>
    );
  }

  return (
    <div className="account-validator-container">
      <h2 className="account-validator-heading">
        {firstName ? `Welcome, ${firstName}!` : 'Complete Your Signup'}
      </h2>
      {firstName && !hasPassword && (
        <div className="account-validador-content">
          <p>
            Please set a password to complete your signup.
            <br /> Enter it twice so you're sure you typed it correctly!
          </p>
        </div>
      )}
      {firstName && hasPassword && (
        <div className="account-validador-content">
          <p>
            Click the button below to validate your email and you will be
            redirected to the app you purchased.
          </p>
        </div>
      )}
      {error && <div className="account-validator-error">{error}</div>}
      {success && (
        <div className="account-validator-success">
          Signup completed successfully! Redirecting...
        </div>
      )}

      {!success && (
        <>
          <div className="account-validator-form">
            {hasPassword || (
              <>
                <div className="account-validator-field">
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    className="account-validator-input"
                    required
                  />
                </div>
                <div className="account-validator-field">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password"
                    className="account-validator-input"
                    required
                  />
                </div>
              </>
            )}
            <button
              onClick={handleValidation}
              className="account-validator-button"
              disabled={!password && !hasPassword}
            >
              {hasPassword
                ? 'Complete Signup'
                : 'Set Password & Complete Signup'}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
