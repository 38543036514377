import React from 'react';

export interface DialogProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({
  isVisible,
  onClose,
  children,
}) => {
  if (!isVisible) return null;

  return (
    <div className="dialog-backdrop" onClick={onClose}>
      <div
        className="dialog-content"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
      >
        <button className="dialog-close" onClick={onClose}>
          ×
        </button>
        {children}
      </div>
    </div>
  );
};
