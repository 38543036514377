import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../config/config';
import { useAuth } from '../hooks/useAuth';
import { EditPlanForm } from '../components/EditPlanForm';
import { StripeIntegrationView } from '../components/StripeIntegrationView';
import { SubscriptionPlan } from '../types';
import { loadFeatures, getFeatureDisplay } from '../utils/featureUtils';
import '../styles/AdminDashboard.css';

const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('plans');
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const [editingPlan, setEditingPlan] = useState<SubscriptionPlan | null>(null);
  const { apiKey } = useAuth();

  const fetchData = async () => {
    const plansRes = await fetch(`${BASE_URL}/subscription-plan`, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': apiKey,
      },
    });

    const plansData = await plansRes.json();
    setPlans(plansData);
  };

  useEffect(() => {
    const loadAllData = async () => {
      await fetchData();
      await loadFeatures(apiKey);
      setFeaturesLoaded(true); // Mark features as loaded
    };

    loadAllData();
  }, [apiKey]);

  const handleEditPlan = (planId: string) => {
    const plan = plans.find((p) => p.id === planId);
    if (plan) {
      setEditingPlan(plan);
    }
  };

  const handleSavePlan = async (updatedPlan: SubscriptionPlan) => {
    setPlans(plans.map((p) => (p.id === updatedPlan.id ? updatedPlan : p))); // Refresh the plans list after save
    // fetchData();
  };

  return (
    <div className="admin-dashboard">
      <h1>Subscription Management</h1>

      <nav className="admin-nav">
        <button
          className={`nav-tab ${activeTab === 'plans' ? 'active' : ''}`}
          onClick={() => setActiveTab('plans')}
        >
          Subscription Plans
        </button>
        <button
          className={`nav-tab ${activeTab === 'stripe' ? 'active' : ''}`}
          onClick={() => setActiveTab('stripe')}
        >
          Stripe Integration
        </button>
      </nav>

      {activeTab === 'plans' && (
        <div className="content-section">
          <table className="data-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Visibility</th>
                <th>Monthly Price</th>
                <th>Annual Price</th>
                <th>Features</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.id}>
                  <td>{plan.name}</td>
                  <td>{plan.visibility}</td>
                  <td>
                    $
                    {plan.renewalIntervals.find((i) => i.interval === 'monthly')
                      ?.cost || '-'}
                  </td>
                  <td>
                    $
                    {plan.renewalIntervals.find((i) => i.interval === 'annual')
                      ?.cost || '-'}
                  </td>
                  <td>
                    <ul className="feature-list">
                      {plan.features.map((f) => (
                        <li
                          key={f}
                          dangerouslySetInnerHTML={{
                            __html: getFeatureDisplay(f),
                          }}
                        />
                      ))}
                    </ul>
                  </td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => handleEditPlan(plan.id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {editingPlan && (
            <EditPlanForm
              plan={editingPlan}
              onClose={() => setEditingPlan(null)}
              onSave={handleSavePlan}
            />
          )}
        </div>
      )}

      {activeTab === 'stripe' && (
        <div className="content-section">
          <StripeIntegrationView />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
