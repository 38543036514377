// import './global.css';
// import './style.css';
import { useState, MouseEvent } from 'react';
import { Logo } from '../icons/Logo';
import '../styles/styleguide.css';
import SubscriptionPlans from '../components/SubscriptionPlans';
import { LoginForm } from '../components/LoginForm';

export const HomePage = () => {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // e.getModifierState('Shift');
    e.stopPropagation();
    console.log(e);
    setShowLogin(true);
  };

  const loginDialog = showLogin ? <LoginForm /> : null;

  return (
    <div className="wrapper cool-background">
      {/* Header */}
      <header className="header">
        <div className="logo">
          <Logo />
        </div>
        {/* <nav className="menu">
          <a href="/about" className="text-wrapper">
            About
          </a>
          <a href="/contact" className="text-wrapper">
            Contact
          </a>
        </nav> */}
        <div className="nav-buttons">
          {/* <button
            className="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleLoginClick(e)
            }
          >
            <div className="button">Login</div>
            <img className="arrow" alt="Arrow" src="/img/arrow.svg" />
          </button> */}
        </div>
      </header>
      {loginDialog}

      {/* Main Content */}
      <main className="main-content">
        <section>
          <SubscriptionPlans />
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Epicenter. All Rights Reserved.</p>
        {/* <div className="sub-link-container">
          <a href="/terms" className="link">
            Terms
          </a>
          <a href="/privacy" className="link">
            Privacy
          </a>
        </div> */}
      </footer>
    </div>
  );
};
