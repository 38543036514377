// src/components/StripeIntegrationView.tsx
import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../config/config';
import { useAuth } from '../hooks/useAuth';
import '../styles/StripeIntegrationView.css';
import { ManageMappingModal } from './ManageMappingModal';
import {
  StripeProduct,
  StripePrice,
  SubscriptionPlan,
  PaymentMetadata,
} from '../types';

export const StripeIntegrationView: React.FC = () => {
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>([]);
  const [stripePrices, setStripePrices] = useState<StripePrice[]>([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [planMappings, setPlanMappings] = useState<PaymentMetadata[]>([]);
  const [editingProduct, setEditingProduct] = useState<StripeProduct | null>(
    null
  );
  const { apiKey } = useAuth();

  const fetchData = async () => {
    try {
      // First get products, prices, and plans
      const [productsRes, pricesRes, plansRes] = await Promise.all([
        fetch(`${BASE_URL}/stripe/products`, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey,
          },
        }),
        fetch(`${BASE_URL}/stripe/prices`, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey,
          },
        }),
        fetch(`${BASE_URL}/subscription-plan`, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey,
          },
        }),
      ]);

      const [productsData, pricesData, plansData] = await Promise.all([
        productsRes.json(),
        pricesRes.json(),
        plansRes.json(),
      ]);

      console.log('Products:', productsData);
      console.log('Prices:', pricesData);
      console.log('Plans:', plansData);

      // Set initial data
      setStripeProducts(productsData.error ? [] : productsData.data);
      setStripePrices(pricesData.error ? [] : pricesData.data);
      setSubscriptionPlans(Array.isArray(plansData) ? plansData : []);

      // Then fetch mappings for each plan
      const plans = Array.isArray(plansData) ? plansData : [];
      const allMappings = await Promise.all(
        plans.map((plan) =>
          fetch(`${BASE_URL}/subscription-plan/${plan.id}/payment-mapping`, {
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
          }).then((res) => res.json())
        )
      );

      console.log('All mappings:', allMappings);

      // Combine all mappings into one array
      const flatMappings = allMappings
        .filter((mapping) => !mapping.error)
        .flatMap((mapping) => mapping);

      setPlanMappings(flatMappings);
    } catch (error) {
      console.error('Error fetching data:', error);
      setStripeProducts([]);
      setStripePrices([]);
      setSubscriptionPlans([]);
      setPlanMappings([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiKey]);

  const getPlanNameForPrice = (priceId: string): string => {
    const mapping = planMappings.find((m) => m.external_id === priceId);
    if (mapping) {
      const plan = subscriptionPlans.find(
        (p) => p.id === mapping.subscription_plan_id
      );
      return plan ? plan.name : '-';
    }
    return '-';
  };

  return (
    <div className="stripe-integration-view">
      <table className="data-table">
        <thead>
          <tr>
            <th>Product</th>
            <th>Monthly Price</th>
            <th>Annual Price</th>
            <th>Subscription Plan</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {stripeProducts.map((product) => {
            const productPrices = stripePrices.filter(
              (p) => p.product === product.id
            );
            const monthlyPrice = productPrices.find(
              (p) => p.recurring?.interval === 'month'
            );
            const yearlyPrice = productPrices.find(
              (p) => p.recurring?.interval === 'year'
            );

            const monthlyPlanName = monthlyPrice
              ? getPlanNameForPrice(monthlyPrice.id)
              : '-';
            const yearlyPlanName = yearlyPrice
              ? getPlanNameForPrice(yearlyPrice.id)
              : '-';

            return (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {monthlyPrice ? `$${monthlyPrice.unit_amount / 100}` : '-'}
                </td>
                <td>
                  {yearlyPrice ? `$${yearlyPrice.unit_amount / 100}` : '-'}
                </td>
                <td>
                  {monthlyPlanName !== '-' && (
                    <div>Monthly: {monthlyPlanName}</div>
                  )}
                  {yearlyPlanName !== '-' && (
                    <div>Annual: {yearlyPlanName}</div>
                  )}
                </td>
                <td>
                  <button
                    className="edit-button"
                    onClick={() => setEditingProduct(product)}
                  >
                    Manage Mapping
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {editingProduct && (
        <ManageMappingModal
          product={editingProduct}
          monthlyPrice={stripePrices.find(
            (p) =>
              p.product === editingProduct.id &&
              p.recurring?.interval === 'month'
          )}
          yearlyPrice={stripePrices.find(
            (p) =>
              p.product === editingProduct.id &&
              p.recurring?.interval === 'year'
          )}
          subscriptionPlans={subscriptionPlans}
          currentMappings={planMappings}
          onClose={() => setEditingProduct(null)}
          onSave={async () => {
            await fetchData();
            setEditingProduct(null);
          }}
        />
      )}
    </div>
  );
};

export default StripeIntegrationView;
