import { Routes, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
// import Login from "./components/login/Login";
import App from '../App';
import ProtectedRoutes from '../generic components/ProtectedRoutes';
import { SessionProvider } from '../contexts/SessionContext';
import LandingPageMain from '../components/landingPage/LandingPageMain';
import { SignUp } from '../screens/SignUp';
import AccountValidator from '../components/AccountValdator';
import PasswordReset from '../components/PasswordReset';
import { HomePage } from '../pages/HomePage';
import { StripeProvider } from '../contexts/StripeProvider';
import AdminDashboard from '../pages/AdminDashboard';
import { LoginPage } from '../pages/LoginPage';
import { AdminRoute } from '../routes/AdminRoute';

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <SessionProvider>
          <StripeProvider>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route path="/complete-signup" element={<AccountValidator />} />
              <Route path="/oldHome" element={<LandingPageMain />} />
              {/* Protected routes */}
              <Route
                path="/admin/*"
                element={
                  <AdminRoute>
                    <AdminDashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/app"
                element={
                  <ProtectedRoutes>
                    <App />
                  </ProtectedRoutes>
                }
              />
              <Route path="*" element={<h1>404 Not Found</h1>} />{' '}
            </Routes>
          </StripeProvider>
        </SessionProvider>
      </BrowserRouter>
    </div>
  );
}
