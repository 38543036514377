export interface StripeProduct {
  id: string;
  name: string;
  description: string;
  active: boolean;
  attributes: string[];
  created: number;
  default_price: string;
  features: string[];
  images: string[];
  livemode: boolean;
  marketing_features: string[];
  metadata: Record<string, string>;
  package_dimensions: any | null;
  shippable: boolean | null;
  statement_descriptor: string | null;
  tax_code: string;
  type: string;
  unit_label: string | null;
  updated: number;
  url: string | null;
}

export interface StripePrice {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: number | null;
  livemode: boolean;
  lookup_key: string | null;
  metadata: Record<string, string>;
  nickname: string | null;
  product: string;
  recurring: {
    aggregate_usage: string | null;
    interval: 'month' | 'year';
    interval_count: number;
    meter: string | null;
    trial_period_days: number | null;
    usage_type: string;
  };
  tax_behavior: string;
  tiers_mode: string | null;
  transform_quantity: any | null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export enum PlanVisibility {
  web = 'web',
  private = 'private',
  internal = 'internal',
  planner = 'planner',
  generator = 'generator',
  git = 'git',
}

export type PlanInterval = 'monthly' | 'annual' | 'perpetual';

export interface SubscriptionPlan {
  id: string;
  name: string;
  renewalIntervals: {
    cost: number;
    interval: PlanInterval;
  }[];
  visibility: PlanVisibility;
  features: string[];
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface PaymentMetadata {
  subscription_plan_id: string;
  payment_provider_id: string;
  external_id: string; // Stripe price ID
  renewal_interval: string; // 'monthly' | 'annual'
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
}

export interface Product {
  id: string;
  name: string;
}

export interface Feature {
  id: string;
  product_id: string;
  code: string;
  name: string;
  description: string | null;
  metadata: {
    availability: 'stable' | 'preview' | 'coming_soon';
    expectedRelease?: string;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

// And let's add a type for the availability status while we're at it
export type FeatureAvailability = 'stable' | 'preview' | 'coming_soon';
