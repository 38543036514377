// src/components/CreateFeatureModal.tsx
import React, { useState } from 'react';
import { BASE_URL } from '../config/config';
import { useAuth } from '../hooks/useAuth';
import '../styles/CreateFeatureModal.css';
import { Feature, FeatureAvailability, Product } from '../types';

interface CreateFeatureModalProps {
  products: Product[];
  initialName?: string; // From the search term
  onClose: () => void;
  onFeatureCreated: (feature: Feature) => void;
}

export const CreateFeatureModal: React.FC<CreateFeatureModalProps> = ({
  products,
  initialName = '',
  onClose,
  onFeatureCreated,
}) => {
  const { apiKey } = useAuth();
  const [name, setName] = useState(initialName);
  const [code, setCode] = useState('');
  const [productId, setProductId] = useState('');
  const [description, setDescription] = useState('');
  const [availability, setAvailability] =
    useState<FeatureAvailability>('stable');
  const [expectedRelease, setExpectedRelease] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/feature`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey,
        },
        body: JSON.stringify({
          product_id: productId,
          code,
          name,
          description,
          metadata: {
            availability,
            ...(availability === 'coming_soon' && { expectedRelease }),
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create feature');
      }

      const feature = await response.json();
      onFeatureCreated(feature);
      onClose();
    } catch (error) {
      console.error('Error creating feature:', error);
      // TODO: Add error handling UI
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="create-feature-modal">
        <h2>Create New Feature</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="product">Product</label>
            <select
              id="product"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              required
            >
              <option value="">Select a product...</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Feature name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              id="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value.toLowerCase())}
              required
              pattern="[a-z0-9_]+"
              maxLength={32}
              placeholder="feature_code"
            />
            <small>Only lowercase letters, numbers, and underscores</small>
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the feature"
            />
          </div>

          <div className="form-group">
            <label htmlFor="availability">Availability</label>
            <select
              id="availability"
              value={availability}
              onChange={(e) =>
                setAvailability(e.target.value as FeatureAvailability)
              }
            >
              <option value="stable">Stable</option>
              <option value="preview">Preview</option>
              <option value="coming_soon">Coming Soon</option>
            </select>
          </div>

          {availability === 'coming_soon' && (
            <div className="form-group">
              <label htmlFor="expectedRelease">Expected Release</label>
              <select
                id="expectedRelease"
                value={expectedRelease}
                onChange={(e) => setExpectedRelease(e.target.value)}
                required
              >
                <option value="">Select quarter...</option>
                <option value="Q1 2024">Q1 2024</option>
                <option value="Q2 2024">Q2 2024</option>
                <option value="Q3 2024">Q3 2024</option>
                <option value="Q4 2024">Q4 2024</option>
              </select>
            </div>
          )}

          <div className="modal-actions">
            <button type="button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Create Feature</button>
          </div>
        </form>
      </div>
    </div>
  );
};
