import { Feature } from '../types';
import { BASE_URL } from '../config/config';

let featureCache: Feature[] | null = null;

export const loadFeatures = async (apiKey: string): Promise<Feature[]> => {
  if (featureCache) return featureCache;

  const response = await fetch(`${BASE_URL}/feature`, {
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': apiKey,
    },
  });

  console.debug(response);
  const features = await response.json();
  console.debug(features);
  featureCache = features;
  return features;
};

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const getFeatureDisplay = (feature: Feature | string): string => {
  if (typeof feature === 'string' && UUID_REGEX.test(feature)) {
    // It's a feature ID, look it up in our cached feature list
    if (!featureCache) {
      return feature;
    }
    if (!Array.isArray(featureCache)) {
      console.warn(
        `Invalid API response: expected an array but got ${typeof featureCache}`
      );
      return feature;
    }
    const cachedFeature = (featureCache as Feature[]).find(
      (f) => f.id === feature
    );
    const className =
      cachedFeature?.metadata?.availability === 'coming_soon'
        ? ' class="coming_soon"'
        : '';
    return `<span${className}>${cachedFeature?.name ?? feature}</span>`;
    // return cachedFeature?.name ?? feature;
  }
  // It's a Feature object or non-UUID string
  return typeof feature === 'string' ? feature : `<span>${feature.name}</span>`;
};
