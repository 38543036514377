import { FC } from 'react';
import Subscribe from './Subscribe';
import { StripeProvider } from '../contexts/StripeProvider';

export const SignUp: FC = () => {
  return (
    <StripeProvider>
      <Subscribe />
    </StripeProvider>
  );
};
