// src/components/EditPlanForm.tsx
import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../config/config';
import { useAuth } from '../hooks/useAuth';
import '../styles/EditPlanForm.css';
import { FeatureSelect } from './FeatureSelect';
import { CreateFeatureModal } from './CreateFeatureModal';
import {
  Feature,
  Product,
  PlanVisibility,
  PlanInterval,
  SubscriptionPlan,
} from '../types';

interface EditPlanFormProps {
  plan: SubscriptionPlan;
  onClose: () => void;
  onSave: (updatedPlan: SubscriptionPlan) => void;
}

export const EditPlanForm: React.FC<EditPlanFormProps> = ({
  plan,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState(plan.name);
  const [visibility, setVisibility] = useState(plan.visibility);
  const [allFeatures, setAllFeatures] = useState<Feature[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateFeature, setShowCreateFeature] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [renewalIntervals, setRenewalIntervals] = useState(
    plan.renewalIntervals
  );
  const { apiKey } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [featuresRes, productsRes] = await Promise.all([
          fetch(`${BASE_URL}/feature`, {
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
          }),
          fetch(`${BASE_URL}/product`, {
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': apiKey,
            },
          }),
        ]);
        if (!featuresRes.ok || !productsRes.ok) {
          setLoading(true);
          console.warn('Failed to fetch features and products');
          return;
        }

        console.debug('Fetching features and products with API key:', apiKey);
        const [featuresData, productsData] = await Promise.all([
          featuresRes.json(),
          productsRes.json(),
        ]);

        setAllFeatures(featuresData);
        setProducts(productsData);

        // Set initially selected features by matching IDs
        const initialFeatures = featuresData.filter((f: Feature) =>
          plan.features.includes(f.id)
        ) as Feature[];
        setSelectedFeatures(initialFeatures);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiKey, plan.features]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/subscription-plan/${plan.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey,
        },
        body: JSON.stringify({
          name,
          visibility,
          features: selectedFeatures.map((f) => f.id), // Send feature IDs
          renewalIntervals,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update plan');
      }

      const updatedPlan = await response.json();
      onSave(updatedPlan);
      onClose();
    } catch (error) {
      console.error('Error updating plan:', error);
      // TODO: Add error handling UI
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="modal-backdrop">
      <div className="edit-plan-form">
        <h2>Edit Plan</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Enter plan name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="visibility-select">Visibility</label>
            <select
              id="visibility-select"
              value={visibility}
              onChange={(e) => setVisibility(e.target.value as PlanVisibility)}
            >
              {Object.values(PlanVisibility).map((v) => (
                <option key={v} value={v}>
                  {v.charAt(0).toUpperCase() + v.slice(1)}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Features</label>
            <FeatureSelect
              features={allFeatures}
              products={products}
              selectedFeatures={selectedFeatures}
              onFeatureSelect={(feature) =>
                setSelectedFeatures([...selectedFeatures, feature])
              }
              onFeatureRemove={(featureId) =>
                setSelectedFeatures(
                  selectedFeatures.filter((f) => f.id !== featureId)
                )
              }
              onCreateNew={() => setShowCreateFeature(true)}
            />
          </div>

          <div className="form-group">
            <label>Renewal Intervals</label>
            {renewalIntervals.map((interval, index) => (
              <div key={index} className="interval-input">
                <label htmlFor={`interval-${index}`}>Interval</label>
                <select
                  id={`interval-${index}`}
                  value={interval.interval}
                  onChange={(e) => {
                    const newIntervals = [...renewalIntervals];
                    newIntervals[index] = {
                      ...interval,
                      interval: e.target.value as PlanInterval,
                    };
                    setRenewalIntervals(newIntervals);
                  }}
                >
                  <option value="monthly">Monthly</option>
                  <option value="annual">Annual</option>
                </select>
                <input
                  type="number"
                  value={interval.cost}
                  onChange={(e) => {
                    const newIntervals = [...renewalIntervals];
                    newIntervals[index] = {
                      ...interval,
                      cost: Number(e.target.value),
                    };
                    setRenewalIntervals(newIntervals);
                  }}
                  placeholder="Cost"
                />
              </div>
            ))}
          </div>

          <div className="form-actions">
            <button type="button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Save Changes</button>
          </div>
        </form>
      </div>

      {showCreateFeature && (
        <CreateFeatureModal
          products={products}
          initialName={searchTerm}
          onClose={() => setShowCreateFeature(false)}
          onFeatureCreated={(feature) => {
            setAllFeatures([...allFeatures, feature]);
            setSelectedFeatures([...selectedFeatures, feature]);
            setShowCreateFeature(false);
          }}
        />
      )}
    </div>
  );
};
