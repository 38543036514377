// src/components/FeatureSelect.tsx
import React, { useState } from 'react';
import { Feature, Product } from '../types';
import '../styles/FeatureSelect.css';

interface FeatureSelectProps {
  features: Feature[];
  products: Product[];
  selectedFeatures: Feature[];
  onFeatureSelect: (feature: Feature) => void;
  onFeatureRemove: (featureId: string) => void;
  onCreateNew: () => void;
}

export const FeatureSelect: React.FC<FeatureSelectProps> = ({
  features,
  products,
  selectedFeatures,
  onFeatureSelect,
  onFeatureRemove,
  onCreateNew,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  console.debug('Features received:', features);
  console.debug('Products received:', products);
  if (!Array.isArray(features)) {
    console.error('Features is not an array:', features);
    return <div>Loading features...</div>;
  }

  // Group features by product
  const featuresByProduct = features.reduce(
    (acc, feature) => {
      const product = products.find((p) => p.id === feature.product_id);
      if (product) {
        acc[product.name] = acc[product.name] || [];
        acc[product.name].push(feature);
      }
      return acc;
    },
    {} as Record<string, Feature[]>
  );

  const getAvailabilityLabel = (feature: Feature) => {
    switch (feature.metadata.availability) {
      case 'coming_soon':
        return `(Coming ${feature.metadata.expectedRelease || 'Soon'})`;
      case 'preview':
        return '(Preview)';
      default:
        return '';
    }
  };

  return (
    <div className="feature-select">
      <div className="feature-search">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search features..."
        />
      </div>

      <div className="selected-features">
        {selectedFeatures.map((feature) => (
          <div key={feature.id} className="selected-feature">
            <span>
              {feature.name} {getAvailabilityLabel(feature)}
            </span>
            <button
              onClick={() => onFeatureRemove(feature.id)}
              className="remove-feature"
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <div className="feature-list">
        {Object.entries(featuresByProduct).map(
          ([productName, productFeatures]) => {
            const filteredFeatures = productFeatures.filter(
              (f) =>
                !selectedFeatures.some((sf) => sf.id === f.id) &&
                (f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  (f.description
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ??
                    false))
            );
            if (filteredFeatures.length === 0) return null;

            return (
              <div key={productName} className="product-group">
                <h4>{productName}</h4>
                {filteredFeatures.map((feature) => (
                  <div
                    key={feature.id}
                    className="feature-option"
                    onClick={() => onFeatureSelect(feature)}
                  >
                    <div className="feature-name">
                      {feature.name}
                      <span className="availability-label">
                        {getAvailabilityLabel(feature)}
                      </span>
                    </div>
                    <div className="feature-description">
                      {feature.description}
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        )}

        {searchTerm && (
          <div className="create-new-option" onClick={onCreateNew}>
            + Create new feature "{searchTerm}"
          </div>
        )}
      </div>
    </div>
  );
};
